import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'
import { filter } from 'lodash'

import { Text, Button, Input, Textarea, Modal, Spacer, Card, Radio, Toggle, Tag, Select } from '@geist-ui/react'
import { Link as GeistLink, Row, Grid, Popover, Table, Display, Image, useModal } from '@geist-ui/react'
import { MoreVertical } from '@geist-ui/react-icons'

import firebase from 'firebase/app'

import Link from 'components/Link'
import Tabs from 'components/Tabs'
import Announcement from 'components/Announcement';
import User from 'components/User';

import InviteModal from 'components/modals/Invite'

import { useWorkspaceBoardsCollection, userSharedRetrosCollection, useYourWorkspaceBoardsCollection, useQueryTab, useCreateBoardDoc, useCurrentWorkspaceDocument, useLocalStorage, useCurrentUser, useCurrentUserShape, useBoolean } from 'utils/hooks'
import { useIsPersonalWorkspace, useCurrentWorkspaceId, useIsDocAdmin, useIsCurrentUserIn } from 'utils/hooks'
import { templates } from 'utils/config'

const CARD_DESCRIPTION_LINE_COUNT = 2
const CARD_DESCRIPTION_LINE_HEIGHT = 26

const yourWorkspaceBoards = ''
const publicBoards = 'public'
const sharedBoards = 'shared'

const EmptyTab = ({ onCreateClick }) => {
  const caption = onCreateClick ?
    <p>That's all. <GeistLink href="/#" color onClick={event => {
      event.preventDefault()
      event.stopPropagation()
      onCreateClick()
    }}>Create</GeistLink> your first baord 💪</p> :
    <p>That's all. Ask for an invite link to join others board</p>

  return (
    <Display caption={caption}>
      <Image width={540} style={{ objectFit: 'contain' }} src="/images/drawkit-content-man-colour.svg" />
    </Display>
  )
}

const SharedBoards = ({ snapshot }) => {
  if (snapshot.empty) return <EmptyTab />

  return (
    <>
      <Spacer />
      <Table
        hover={false}
        data={snapshot.docs.map(doc => {
          const { title, description } = doc.data()
          const link = <Link href={`~/board?doc=${doc.id}`} color icon>Open</Link>
          return ({ doc, title, description, link })
        })}
      >
        <Table.Column prop="title" label="title" width={250} />
        <Table.Column prop="description" label="description" />
        <Table.Column prop="link" label="open board" width={150} />
      </Table>
    </>
  )
}

const WorkspaceCard = ({ doc }) => {
  const { id } = doc
  const { title, description, visibility, participants } = doc.data()

  const [processingJoin, onJoinProcess, offJoinProcess] = useBoolean(false)

  const userShape = useCurrentUserShape()

  const isBoardAdmin = useIsDocAdmin(doc)
  const isParticipant = useIsCurrentUserIn(participants)
  const isPublic = visibility === 'public'

  const onDeleteClick = () => doc.ref.delete()
  const onJoinClick = async () => {
    onJoinProcess()

    await doc.ref.update({
      participants: firebase.firestore.FieldValue.arrayUnion(userShape.uid),
      [`roles.${userShape.uid}`]: 'member',
      [`users.${userShape.uid}`]: userShape,
    });

    offJoinProcess()
  }

  return (
    <>
      <Card>
        <Row justify="space-between" align="middle">
          <div className="flex items-center space-x-2">
            <Text size={20} span b>
              <div className="card-title-content">{title}</div>
            </Text>
            {isPublic && <Tag type="lite">Public</Tag>}
          </div>
          {isBoardAdmin && (
              <Popover
                content={(
                  <>
                    <Popover.Item onClick={onDeleteClick}>
                      <span>Delete</span>
                    </Popover.Item>
                  </>
                )}
              >
              <MoreVertical />
            </Popover>
          )}
        </Row>
        <Text>
          <div className="card-text-content">{description}</div>
        </Text>
        <Card.Footer>
          {isParticipant && !processingJoin ? (
            <Link href={`~/board?doc=${id}`} color icon>Go to board</Link>
          ) : (
            <Button size="mini" onClick={onJoinClick} loading={processingJoin}>Join public board</Button>
          )}
        </Card.Footer>
      </Card>

      <style jsx>{`
        .card-text-content {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: ${CARD_DESCRIPTION_LINE_COUNT};
          -webkit-box-orient: vertical;
          height: ${CARD_DESCRIPTION_LINE_COUNT * CARD_DESCRIPTION_LINE_HEIGHT}px;
        }
        .card-title-content {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      `}</style>
    </>
  )
}

const ToggleRow = ({ label, description, value, ...rest }) => {
  return (
    <Row justify="space-between">
      <div >
        <Text className="m-0">{label}</Text>
        <Text small type="secondary" p={false}>{description}</Text>
      </div>
      <Toggle {...rest} checked={value} size="large" />
    </Row>
  )
}

const WorkspaceBoards = ({ snapshot, workspaceDoc }) => {
  const isPersonalWorkspace = useIsPersonalWorkspace()
  const worksapceId = useCurrentWorkspaceId()
  const user = useCurrentUser()

  const createBoardDoc = useCreateBoardDoc()
  const router = useRouter()

  const isWorkspaceAdmin = useIsDocAdmin(workspaceDoc)

  const [title, setTitle] = useState('')
  const [titleError, setTitleError] = useState(false)
  const [description, setDescription] = useState('')

  const { setVisible, bindings } = useModal()
  const { setVisible: setVisibleInvite, bindings: bindingsInvite } = useModal(false)

  const [showMoreTemplates, showMore, showLess] = useBoolean(false)
  const [template, setTemplate] = useState(templates.start_stop_continue.id)

  const [publicBoard, setPublicBoard] = useState(false)
  const [anonymousBoard, setAnonymousBoard] = useState(false)

  const [participants, setParticipants] = useState([])

  const { members, users } = workspaceDoc.data()

  const clearInputs = () => {
    setVisible(false)
    setTitle('')
    setDescription('')
  }

  useEffect(() => {
    if (titleError && title) {
      setTitleError(false)
    }
  }, [title, titleError])

  const onCreateClick = async () => {
    if (!title) return setTitleError(true)

    const participantsUsers = participants.reduce((acc, uid) => {
      acc[uid] = users[uid]
      return acc
    }, {})
    const ref = await createBoardDoc({ title, description, template, publicBoard, anonymousBoard, participants, participantsUsers })

    clearInputs()

    if (isPersonalWorkspace) {
      router.push(`/board?doc=${ref.id}`)
    } else {
      router.push(`/w/${worksapceId}/board?doc=${ref.id}`)
    }
  }

  const onInviteTeamClick = () => {
    if (isPersonalWorkspace) {
      router.push(`/create-workspace`)
    } else {
      setVisibleInvite(true)
    }
  }

  const onCancelClick = () => {
    clearInputs()
    setVisible(false)
  }

  let content = null

  if (snapshot.empty) {
    content = <EmptyTab onCreateClick={() => setVisible(true)} />
  } else {
    content = (
      <>
        <Grid.Container gap={2} justify="flex-start">
          {snapshot.docs.map(doc =>
             <Grid key={doc.id} md={8} sm={12} xs={24}>
              <WorkspaceCard doc={doc} />
            </Grid>
          )}
        </Grid.Container>  
      </>
    )
  }

  console.log(participants)

  return (
    <>
      <Spacer />
  
      <Grid.Container gap={2} justify="flex-end" alignItems="center">
        {isWorkspaceAdmin && (
          <Grid>
            <Button auto onClick={onInviteTeamClick}>Invite Team</Button>
          </Grid>
        )}
        <Grid>
          <Button type="secondary" onClick={() => setVisible(true)}>New Board</Button>
        </Grid>
      </Grid.Container>

      {content}

      {workspaceDoc && <InviteModal doc={workspaceDoc} {...bindingsInvite} />}

      <Modal {...bindings}>
        <Modal.Title>New Retro Board</Modal.Title>
        <Modal.Content>
          <Input
            placeholder="Sprint 13"
            width="100%"
            value={title}
            status={titleError ? 'error' : 'default'}
            onChange={e => setTitle(e.target.value)}
          >
            Board title
          </Input>
          <Spacer y={.5} />
          <Textarea width="100%" placeholder="Retro description (optional)" value={description} onChange={e => setDescription(e.target.value)} />


          <Spacer />
          <ToggleRow
            label="Anonymous board"
            description="All notes in this retro board will be fully anonymous. Users will be not be able to delete created note."
            value={anonymousBoard}
            onChange={event => setAnonymousBoard(event.target.checked)}
          />

          {!isPersonalWorkspace && (
            <>
              <Spacer />
              <ToggleRow
                label="Public board"
                description="This board will be visible to all workspace members. Any workspace member could join this board."
                value={publicBoard}
                onChange={event => setPublicBoard(event.target.checked)}
              />
            </>
          )}

{          !isPersonalWorkspace && (
            <>
              <Spacer />
              <Text>Add members to baord:</Text>
              <Select placeholder="Board participants" multiple width="100%" onChange={setParticipants} value={participants}>
                {filter(members, uid => uid !== user.uid).map(uid => (
                  <Select.Option key={uid} value={uid} className="leading-none">
                    <div className="p-2">
                      <User user={users[uid]} size="mini" />
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </>
          )}

          <Spacer />  
          <Text>Select board template:</Text>

          <Radio.Group value={template} onChange={setTemplate}>
            <Radio value={templates.went_well.id}>
              What Went Well, What Didn't go Well
              <Radio.Description>A basic retrospective technique that focuses on your team's strengths and weaknesses.</Radio.Description>
            </Radio>
            <Radio value={templates.mad_sad_glad.id}>
              Mad, Sad, Glad
              <Radio.Desc>Is a popular retrospective technique that encourages team members to think about their emotions. How does the team feel after the last sprint? Mad, Sad, Glad is simple to learn while being easy to explain and run.</Radio.Desc>
            </Radio>
            <Radio value={templates.start_stop_continue.id}>
              Start, Stop, Continue
              <Radio.Desc>Is an action-oriented retrospective technique that encourages participants to come up with practical ideas for team-based improvement.</Radio.Desc>
            </Radio>
            {showMoreTemplates && (
              <>
                <Radio value={templates['4ls'].id}>
                  4Ls
                  <Radio.Desc>Stands for Liked, Learned, Lacked, and Longed For. 4Ls is a popular, frequently used technique that is simple to set up for the facilitator and easy to understand for the participants.</Radio.Desc>
                </Radio>
                <Radio value={templates.lean_coffee.id}>
                  Lean Coffee™
                  <Radio.Desc>Is a format for democratically generating an agenda of topics at the start of a meeting. This is a great technique for having an open-ended conversation that is owned and directed by the team.</Radio.Desc>
                </Radio>
                <Radio value={templates.wishes_risks_appreciations_puzzles.id}>
                  Wishes, Risks, Appreciations, and Puzzles
                  <Radio.Desc>Is a retrospective technique that enables the team to create notes related to what they like, what questions they have, what future pitfalls they could see, and what they'd love to see happen.</Radio.Desc>
                </Radio>
                <Radio value={templates.sprint_goal.id}>
                  Sprint Goal
                  <Radio.Desc>The Sprint Goal retro will guide a team to come to a consensus on a valuable Sprint Goal. This retro looks at ways the product can be improved, helpers and blockers to the potential goal, and allows team members to propose their own Sprint Goal for their team to discuss.</Radio.Desc>
                </Radio>
                {/* <Radio value="custom">
                  Custom
                  <Radio.Desc>Create your own headings and allow the team to add notes to each column.</Radio.Desc>
                </Radio> */}
              </>
            )}
          </Radio.Group>
          <Button auto type="abort" onClick={showMoreTemplates ? showLess : showMore}>Show {showMoreTemplates ? 'less' : 'more'} templates</Button>

        </Modal.Content>
        <Modal.Action passive onClick={onCancelClick}>Cancel</Modal.Action>
        <Modal.Action onClick={onCreateClick}>Create</Modal.Action>
      </Modal>
    </>
  )
}

const TabContent = ({ yourBoardsSnaphot, boardsSnapshot, sharedShanpshot, workspaceDoc }) => {
  const tab = useQueryTab()

  switch (tab) {
    case yourWorkspaceBoards: return <WorkspaceBoards snapshot={yourBoardsSnaphot} workspaceDoc={workspaceDoc} />
    case publicBoards: return <WorkspaceBoards snapshot={boardsSnapshot} workspaceDoc={workspaceDoc} />
    case sharedBoards: return <SharedBoards snapshot={sharedShanpshot} workspaceDoc={workspaceDoc} />
    default: return null
  }
}

const AdminWorkspaceHome = ({ workspaceDoc }) => {
  const [yourWorkspaceBoardsQuerySnapshot, loadingYourBoards] = useYourWorkspaceBoardsCollection()
  const [workspaceBoardsQuerySnapshot, loadingAllBoards] = useWorkspaceBoardsCollection([['visibility', '==', 'public']])

  if (loadingYourBoards || loadingAllBoards) return null

  return (
    <>
      <Tabs>
        <Tabs.Item label="Your boards" value={yourWorkspaceBoards} />
        <Tabs.Item label="Public boards" value={publicBoards} />
      </Tabs>

      <TabContent
        yourBoardsSnaphot={yourWorkspaceBoardsQuerySnapshot}
        boardsSnapshot={workspaceBoardsQuerySnapshot}

        workspaceDoc={workspaceDoc}
      />
    </>
  )
}

const PersonalWorkspaceHome = ({ workspaceDoc }) => {
  const [yourWorkspaceBoardsQuerySnapshot, loadingYourBoards] = useYourWorkspaceBoardsCollection()
  const [sharedRetrosQuerySnapshot, loadingShared] = userSharedRetrosCollection()

  if (loadingYourBoards || loadingShared) return null

  return (
    <>
      <Tabs>
        <Tabs.Item label="Your boards" value={yourWorkspaceBoards} />
        <Tabs.Item label="Shared boards" value={sharedBoards} />
      </Tabs>

      <TabContent
        yourBoardsSnaphot={yourWorkspaceBoardsQuerySnapshot}
        sharedShanpshot={sharedRetrosQuerySnapshot}

        workspaceDoc={workspaceDoc}
      />
    </>
  )
}

const MemberWorkspaceHome = ({ workspaceDoc }) => {
  const [yourWorkspaceBoardsQuerySnapshot, loadingYourBoards] = useYourWorkspaceBoardsCollection()
  const [workspaceBoardsQuerySnapshot, loadingAllBoards] = useWorkspaceBoardsCollection([['visibility', '==', 'public']])

  if (loadingYourBoards || loadingAllBoards) return null

  return (
    <>
      <Tabs>
        <Tabs.Item label="Your boards" value={yourWorkspaceBoards} />
        <Tabs.Item label="Public boards" value={publicBoards} />
      </Tabs>

      <TabContent
        yourBoardsSnaphot={yourWorkspaceBoardsQuerySnapshot}
        boardsSnapshot={workspaceBoardsQuerySnapshot}

        workspaceDoc={workspaceDoc}
      />
    </>
  )
}

export default function HomePage() {
  const [workspaceDoc, loading, error] = useCurrentWorkspaceDocument()
  const isWorkspaceAdmin = useIsDocAdmin(workspaceDoc)
  const isPersonalWorkspace = useIsPersonalWorkspace()

  const [showTeamAnnoucement, setShowTeamAnnoucement] = useLocalStorage('team-workspaces-announcement', true)

  if (loading || error) return null

  return (
    <>
      <Head>
        <title>Backdated</title>
      </Head>

      {showTeamAnnoucement && isPersonalWorkspace && (
        <Announcement
          title="Team workspaces are here! ✨"
          description="We're adding support for team workspaces under one user account. This makes it easier to keep your personal retro boards in a separate workspace from your company's workspace or to collaborate with multiple companies without logging in and out."
          onClose={() => setShowTeamAnnoucement(false)}
        />
      )}

      {isPersonalWorkspace ? (
        <PersonalWorkspaceHome workspaceDoc={workspaceDoc} />
      ) : (
        isWorkspaceAdmin ? (
          <AdminWorkspaceHome workspaceDoc={workspaceDoc} />
        ) : (
          <MemberWorkspaceHome workspaceDoc={workspaceDoc} />
        )
      )}
    </>
  )
}
